import "@pnp/sp/webs";
import "@pnp/sp/files";
import "@pnp/sp/items";
import "@pnp/sp/folders";
import "@pnp/sp/lists";
import "@pnp/sp/site-groups/web";
import "@pnp/graph/users";
import { spfi, SPBrowser } from "@pnp/sp";
import { BearerToken } from "@pnp/queryable";
import * as Constants from "./../core/Constants";
import { fetchPdfFile } from "./api";
import { handleFileUpload } from "./documents-manager";
import {
  getAreAllServicesCompleted,
  isFinalorOutdatedVersion,
  isOutdatedVersion,
  mapServiceCodesToServicesDTOs,
} from "./service-utils";
import { findQuestionInService } from "./shared";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "./../core/Models";
import {
  handleTemplateAuditLetter,
  handleTemplateEngagementCounselingLetter,
  handleTemplateEngagementLetter,
  handleTemplateEngagementLetterAppendix,
} from "./docxtemplater-utils";
import { AppState, ContactPersonItem } from "src/core/Models/Core.interface";
import { mapQuestionCodesToQuestionDTOs } from "./question-utils";
import { formatDate, isDateBefore } from "./utils";
import { t } from "i18next";
import { QuestionDTO } from "src/core/Models/DTO.interface";

const SP_DOCUMENTS_FOLDER = process.env.REACT_APP_SP_DOCUMENTS_FOLDER;
const SP_DOCUMENTS_URL = process.env.REACT_APP_SP_DOCUMENTS_URL;

const getSP = (bearerToken: string) =>
  spfi().using(
    SPBrowser({
      baseUrl: SP_DOCUMENTS_URL,
    }),
    BearerToken(bearerToken)
  );

export const resolveSPFolder = async (
  engagementConfigurationId: string,
  bearerToken: string
) => {
  const sp = getSP(bearerToken);
  try {
    await sp.web
      .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
      .folders.getByUrl(engagementConfigurationId)
      .getItem();
  } catch (Ex: any) {
    if (!!Ex && Ex.status === 404) {
      await sp.web
        .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
        .folders.addUsingPath(engagementConfigurationId);
    } else {
      throw Ex;
    }
  }
};

export const removeOldMetadataDocuments = async (
  currentConfiguration: any,
  documentPageConfig: CorePropsInterfaces.DocumentPageProps,
  bearerToken: string
) => {
  const sp = getSP(bearerToken);
  try {
    const filenameDateStampPart =
      currentConfiguration.engagementLetterMetadata?.version > 0
        ? "_" +
          formatDate(
            currentConfiguration.engagementLetterMetadata.createdDate,
            Constants.HelpfulConstants.DateFormatTrimYearMonthDayHourMinutes
          )
        : "";
    const fileNamesToDelete = documentPageConfig.engagementDocumentTypes.map(
      (documentType: string) =>
        `${
          documentType === Constants.EngagementDocumentType.AuditLetter
            ? Constants.EngagementDocumentType.EngagementLetter
            : documentType
        }${filenameDateStampPart}.${Constants.FileExtension.Docx}`
    );
    await Promise.all(
      fileNamesToDelete.map((fileName) =>
        sp.web
          .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
          .folders.getByUrl(currentConfiguration.id)
          .files.getByUrl(fileName)
          .delete()
          .catch((Ex: any) => {
            if (!!Ex && Ex.status === 404) {
              console.log(`File to delete not found ${fileName}...skipping`);
            }
          })
      )
    );
  } catch (Ex: any) {
    console.log("Exception when removing files", Ex);
    if (!!Ex && Ex.status === 404) {
      console.log("File to delete not found...skipping");
    } else {
      throw Ex;
    }
  }
};

export const sendFileToSharepoint = (
  engagementConfigurationId: string,
  fileNamePath: string,
  fileContent: Blob,
  bearerToken: string
) => {
  const sp = getSP(bearerToken);
  return sp.web
    .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
    .folders.getByUrl(engagementConfigurationId)
    .files.addUsingPath(fileNamePath, fileContent, {
      Overwrite: true,
    });
};

export const readFileAsArrayBufferFromSP = (
  engagementConfigurationId: string,
  fileNamePath: string,
  bearerToken: string
): Promise<ArrayBuffer> => {
  const sp = getSP(bearerToken);
  return sp.web
    .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
    .folders.getByUrl(engagementConfigurationId)
    .files.getByUrl(fileNamePath)
    .getBuffer();
};

export const deleteSPEngagementFolder = (
  engagementConfigurationId: string,
  bearerToken: string
): Promise<void> => {
  const sp = getSP(bearerToken);
  return sp.web
    .getFolderByServerRelativePath(SP_DOCUMENTS_FOLDER)
    .folders.getByUrl(engagementConfigurationId)
    .delete()
    .catch((Ex: any) => {
      console.log("Unable to delete the SharePoint folder.");
    });
};

export function openSharepointURLInMsWordClient(
  engagementConfigurationId: string,
  documentTypeInput: Constants.EngagementDocumentType,
  readOnly: boolean,
  engagementLetterMetadata: CoreInterfaces.EngagementLetterMetadata
): void {
  const documentType =
    documentTypeInput === Constants.EngagementDocumentType.AuditLetter
      ? Constants.EngagementDocumentType.EngagementLetter
      : documentTypeInput;

  let fileSuffix = "";
  if (
    engagementLetterMetadata?.version > 0 &&
    engagementLetterMetadata.createdDate
  ) {
    fileSuffix =
      "_" +
      formatDate(
        engagementLetterMetadata.createdDate,
        Constants.HelpfulConstants.DateFormatTrimYearMonthDayHourMinutes
      );
  }

  const sharepointURL = `${SP_DOCUMENTS_URL}/${SP_DOCUMENTS_FOLDER}/${engagementConfigurationId}/${documentType}${fileSuffix}.docx?nocache=${new Date().getTime()}`;
  const url = new URL(sharepointURL);
  let wordClientUrl = `ms-word:ofe|u|${url.origin}${url.pathname}`;
  if (readOnly === true) {
    wordClientUrl = `ms-word:ofe|u|${url.origin}${
      url.pathname
    }?t=${Date.now()}`;
  }
  window.open(wordClientUrl, "_blank");
}

export function executeFinalizeEngagementDocumentFlow(
  businessOportunityId: string,
  globalState: CoreInterfaces.AppState,
  documentTypeInput: Constants.EngagementDocumentType,
  accessToken: string
): Promise<void> {
  const {
    currentConfiguration: {
      id: engagementConfigurationId,
      engagementLetterMetadata,
    },
    generalVariables: { companyName, organisationNo },
  } = globalState;
  const documentType =
    documentTypeInput === Constants.EngagementDocumentType.AuditLetter
      ? Constants.EngagementDocumentType.EngagementLetter
      : documentTypeInput;

  let fileTimeSuffix = "";
  if (!!engagementLetterMetadata?.version) {
    fileTimeSuffix =
      "_" +
      formatDate(
        engagementLetterMetadata.createdDate,
        Constants.HelpfulConstants.DateFormatTrimYearMonthDayHourMinutes
      );
  }
  let filename = documentType;

  switch (documentTypeInput) {
    case Constants.EngagementDocumentType.AuditLetter:
      filename = t("General.FilenameAudit", {
        companyName: companyName,
        orgNr: organisationNo,
      });
      break;
  }

  return readFileAsArrayBufferFromSP(
    engagementConfigurationId,
    `${documentType}${fileTimeSuffix}.${Constants.FileExtension.Docx}`,
    accessToken
  )
    .then((arrayBuffer: ArrayBuffer) => {
      const engagementLetterBlob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const formdata = new FormData();
      formdata.append(
        "file",
        engagementLetterBlob,
        `${filename}.${Constants.FileExtension.Pdf}`
      );
      return fetchPdfFile(
        Constants.APIPath.ConvertToPdf,
        Constants.APIMethod.POST,
        formdata
      );
    })
    .then((pdfFileContent: Blob) => {
      return handleFileUpload(
        businessOportunityId,
        engagementConfigurationId,
        pdfFileContent,
        documentType,
        null,
        [],
        `${filename}.${Constants.FileExtension.Pdf}`
      );
    });
}

export function resolveGenerateDocumentsPageConfiguration(
  globalState: CoreInterfaces.AppState,
  businessUnit: Constants.BusinessUnit
): CorePropsInterfaces.DocumentPageProps {
  const documentConfig: CorePropsInterfaces.DocumentPageProps = {
    engagementDocumentTypes: [],
    isEngagementLetterGenerated: false,
    isEngagementLetterRegenerable: false,
    isEngagementCounselingSelected: false,
    isEngagementDescriptionAreaVisible: false,
    isFinalVersion: false,
    isFinalOrOutdatedVersionStatus: false,
    isOutdatedVersionStatus: false,
    shouldShowObsoleteEngagementLetterNotification: false,
    areAllServicesCompleted: false,
    isAuditBusinessUnit: false,
    reGenerateButtonLabel: "",
    templateDataFnsMap: {},
  };

  const genericService = globalState.services.find(
    (serviceDTO) =>
      serviceDTO.data.code === Constants.ServiceCode.GeneralInformation
  );
  const engagementCounselingService = globalState.services.find(
    (service) =>
      service.data.code === Constants.ServiceCode.EngagementCounseling &&
      service.state.isSelected
  );

  const q0022 = findQuestionInService(
    genericService,
    Constants.GeneralInformationQuestion.Q0022
  );
  const isGtPersonalDataProcessor = findQuestionInService(
    genericService,
    Constants.GeneralInformationQuestion.Q0040
  );
  const isQ0022Yes = q0022.data.userValue === Constants.YesNo.Yes;
  const isEngagementCounselingSelected =
    !!engagementCounselingService &&
    engagementCounselingService.state.isSelected;
  const areAllServicesCompleted = getAreAllServicesCompleted(
    globalState.services.filter(
      (service: DTOs.ServiceDTO) => service.state.isSelected
    ),
    businessUnit
  );
  documentConfig.isAuditBusinessUnit =
    businessUnit === Constants.BusinessUnit.Audit;

  documentConfig.templateDataFnsMap = {
    [Constants.EngagementDocumentType.EngagementLetter]: (
      globalState: AppState,
      genericService: DTOs.ServiceDTO,
      language: Constants.Languages
    ) => handleTemplateEngagementLetter(globalState, genericService, language),
    [Constants.EngagementDocumentType.CounselingLetter]: (
      globalState: AppState,
      genericService: DTOs.ServiceDTO,
      language: Constants.Languages
    ) =>
      handleTemplateEngagementCounselingLetter(
        globalState,
        genericService,
        language
      ),
    [Constants.EngagementDocumentType.Appendix]: (globalState: AppState) =>
      handleTemplateEngagementLetterAppendix(globalState),
    [Constants.EngagementDocumentType.AuditLetter]: (
      globalState: AppState,
      genericService: DTOs.ServiceDTO,
      language: Constants.Languages
    ) => handleTemplateAuditLetter(globalState, genericService, language),
  };
  documentConfig.isEngagementLetterGenerated =
    !!globalState.currentConfiguration.engagementLetterMetadata?.createdDate ||
    (q0022.state.isShown && !isQ0022Yes);
  documentConfig.isEngagementLetterRegenerable =
    isQ0022Yes ||
    !!isEngagementCounselingSelected ||
    (!!documentConfig.isAuditBusinessUnit &&
      globalState.services.filter(
        (service: DTOs.ServiceDTO) => service.state.isSelected
      ).length > 1);
  documentConfig.isEngagementCounselingSelected =
    isEngagementCounselingSelected;
  documentConfig.isFinalVersion =
    globalState.currentConfiguration.status ===
    Constants.ConfigurationStatus.FinalVersion;
  documentConfig.isFinalOrOutdatedVersionStatus =
    isFinalorOutdatedVersion(globalState);
  documentConfig.isOutdatedVersionStatus = isOutdatedVersion(globalState);
  const engagementGenerationDate =
    globalState.currentConfiguration.engagementLetterMetadata?.createdDate;
  if (
    engagementGenerationDate &&
    globalState.currentConfiguration.lastUserChangesDate &&
    isDateBefore(
      engagementGenerationDate,
      globalState.currentConfiguration.lastUserChangesDate
    )
  ) {
    documentConfig.shouldShowObsoleteEngagementLetterNotification = true;
  }
  documentConfig.isEngagementDescriptionAreaVisible =
    !documentConfig.isAuditBusinessUnit &&
    !isEngagementCounselingSelected &&
    areAllServicesCompleted;
  documentConfig.areAllServicesCompleted = areAllServicesCompleted;
  documentConfig.reGenerateButtonLabel = globalState.currentConfiguration
    .engagementLetterMetadata?.createdDate
    ? "General.ReGenerateLetterAndAppendix"
    : "General.GenerateLetterAndAppendix";

  if (!isEngagementCounselingSelected) {
    if (isQ0022Yes) {
      documentConfig.engagementDocumentTypes = [
        Constants.EngagementDocumentType.EngagementLetter,
        Constants.EngagementDocumentType.Appendix,
      ];
    } else {
      if (businessUnit === Constants.BusinessUnit.Audit) {
        documentConfig.engagementDocumentTypes = [
          Constants.EngagementDocumentType.AuditLetter,
        ];
        documentConfig.reGenerateButtonLabel = globalState.currentConfiguration
          .engagementLetterMetadata?.createdDate
          ? "General.ReGenerateAuditLetter"
          : "General.GenerateAuditLetter";
      }
    }
  } else {
    if (
      isGtPersonalDataProcessor.state.isShown &&
      isGtPersonalDataProcessor.data.userValue === Constants.YesNo.No
    ) {
      documentConfig.engagementDocumentTypes = [
        Constants.EngagementDocumentType.CounselingLetter,
      ];
      documentConfig.reGenerateButtonLabel = globalState.currentConfiguration
        .engagementLetterMetadata?.createdDate
        ? "General.ReGenerateLetter"
        : "General.GenerateLetter";
    } else {
      documentConfig.engagementDocumentTypes = [
        Constants.EngagementDocumentType.CounselingLetter,
        Constants.EngagementDocumentType.Appendix,
      ];
    }
  }
  return documentConfig;
}

const digitalSigningContactsMapper = (
  digitalSigningUser: {
    email: string;
    firstName: string;
    lastName: string;
  },
  orderOfSigning: number
) => ({
  Email: digitalSigningUser.email,
  FirstName: digitalSigningUser.firstName,
  LastName: digitalSigningUser.lastName,
  OrderOfSigning: orderOfSigning,
});

export function resolveDigitalSigningContacts(
  globalState: CoreInterfaces.AppState
): Array<CoreInterfaces.ContactPersonPack> {
  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.GeneralInformation,
    Constants.ServiceCode.EngagementCounseling,
  ]);
  let digitalSigningUsers = [];
  const { Q0003, Q0018, Q0022, Q0033, Q0034, QA0006 } =
    Constants.GeneralInformationQuestion;
  const questionDTOs = mapQuestionCodesToQuestionDTOs(
    servicesMap[Constants.ServiceCode.GeneralInformation],
    [Q0003, Q0018, Q0022, Q0033, Q0034, QA0006]
  );
  if (servicesMap[Constants.ServiceCode.EngagementCounseling]) {
    const hasOnlyEngagementDescription =
      !servicesMap[Constants.ServiceCode.EngagementCounseling].state
        .isSelected &&
      questionDTOs[Q0022].state.isShown &&
      questionDTOs[Q0022].data.userValue === Constants.YesNo.No;

    let contactIds: Array<number> = [];
    const q0018ContactIds = questionDTOs[Q0018].data.userValue as Array<number>;

    if (hasOnlyEngagementDescription) {
      if (Array.isArray(questionDTOs[Q0018].data.userValue)) {
        contactIds = [q0018ContactIds[0]];
      }
    } else {
      if (
        questionDTOs[Q0033].state.isShown &&
        questionDTOs[Q0033].data.userValue === Constants.YesNo.Yes
      ) {
        if (Array.isArray(questionDTOs[Q0034].data.userValue)) {
          contactIds = (
            questionDTOs[Q0034].data
              .userValue as Array<CoreInterfaces.ContactPersonUserDetails>
          )
            .map((eachContactPersonUserDetail) =>
              Number(eachContactPersonUserDetail.ContactPersonId)
            )
            .filter((eachValue) => !isNaN(eachValue));
        }
      } else {
        contactIds = q0018ContactIds;
      }
    }

    digitalSigningUsers = globalState.remoteData.customerContactPersons
      .filter((item) => contactIds.includes(Number(item.key)))
      .map((user) =>
        digitalSigningContactsMapper(
          user,
          Constants.OrderOfSigningIndex.Customer
        )
      );

    if (!hasOnlyEngagementDescription) {
      const q0003SelectedOptions = globalState.remoteData.gtContactPersons
        .filter(
          (eachOption: CoreInterfaces.ContactPersonItem) =>
            eachOption.key == questionDTOs[Q0003].data.userValue
        )
        .map((user) =>
          digitalSigningContactsMapper(
            user,
            Constants.OrderOfSigningIndex.GTOwner
          )
        );
      if (q0003SelectedOptions.length > 0) {
        digitalSigningUsers.push(q0003SelectedOptions[0]);
      }
    }

    return digitalSigningUsers;
  } else {
    let digitalSigningUsers = [];
    const params = new URLSearchParams(window.location.search);
    const businessUnit = params.get("unit");
    if (businessUnit === Constants.BusinessUnit.Audit) {
      const contactIds: Array<number> = questionDTOs[Q0018].data
        .userValue as Array<number>;
      const qA0006ContactIds = questionDTOs[QA0006].data
        .userValue as Array<number>;

      digitalSigningUsers = globalState.remoteData.customerContactPersons
        .filter((item) => contactIds.includes(Number(item.key)))
        .map((user) =>
          digitalSigningContactsMapper(
            user,
            Constants.OrderOfSigningIndex.Customer
          )
        );

      const qA0006SelectedOptions = globalState.remoteData.gtContactPersons
        .filter((item) => qA0006ContactIds.includes(Number(item.key)))
        .map((user) =>
          digitalSigningContactsMapper(
            user,
            Constants.OrderOfSigningIndex.GTOwner
          )
        );

      if (qA0006SelectedOptions.length > 0) {
        digitalSigningUsers.push(qA0006SelectedOptions[0]);
      }

      return digitalSigningUsers;
    }
  }
}

export function resolveDigitalSignerNameForEngagementLetter(
  globalState: CoreInterfaces.AppState
): string {
  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.GeneralInformation,
    Constants.ServiceCode.EngagementCounseling,
  ]);
  const { Q0018, Q0022, Q0033, Q0034, Q0038, QA0006 } =
    Constants.GeneralInformationQuestion;
  const questionDTOs = mapQuestionCodesToQuestionDTOs(
    servicesMap[Constants.ServiceCode.GeneralInformation],
    [Q0018, Q0022, Q0033, Q0034, Q0038, QA0006]
  );

  const isAuditBusinessUnit =
    globalState.currentConfiguration.businessUnit ===
    Constants.BusinessUnit.Audit;
  const isEngagementCounselingSelected =
    servicesMap[Constants.ServiceCode.EngagementCounseling]?.state.isSelected;
  const isQ0022No =
    questionDTOs[Q0022].state.isShown &&
    questionDTOs[Q0022].data.userValue === Constants.YesNo.No;
  const isQ0033Yes =
    questionDTOs[Q0033].state.isShown &&
    questionDTOs[Q0033].data.userValue === Constants.YesNo.Yes;
  const isQ0038OwnerDirectives =
    questionDTOs[Q0038].state.isShown &&
    questionDTOs[Q0038].data.userValue ===
      Constants.CounselingAssignment.OwnerDirectives;
  const hasOnlyEngagementDescription =
    !isAuditBusinessUnit && !isEngagementCounselingSelected && isQ0022No;

  let contactIds: number;
  let contactPersonsList: ContactPersonItem[];

  if (isAuditBusinessUnit) {
    contactIds = getTheFirstSigneeContactIdsFromQuestion(questionDTOs[QA0006]);
    contactPersonsList = globalState.remoteData.gtContactPersons;
  } else {
    contactPersonsList = globalState.remoteData.customerContactPersons;

    if (hasOnlyEngagementDescription) {
      contactIds = getTheFirstSigneeContactIdsFromQuestion(questionDTOs[Q0018]);
    } else if (isQ0033Yes || isQ0038OwnerDirectives) {
      contactIds = getTheFirstSigneeContactIdsFromQuestion(questionDTOs[Q0034]);
    } else {
      contactIds = getTheFirstSigneeContactIdsFromQuestion(questionDTOs[Q0018]);
    }
  }

  const contact = contactPersonsList.find((x) => Number(x.key) === contactIds);
  return contact ? `${contact.firstName} ${contact.lastName}` : null;
}

function getTheFirstSigneeContactIdsFromQuestion(
  questionDTO: QuestionDTO
): number {
  let contactIds: number[];

  switch (questionDTO.data.code) {
    case Constants.GeneralInformationQuestion.Q0034:
      contactIds = (
        questionDTO.data
          .userValue as Array<CoreInterfaces.ContactPersonUserDetails>
      )
        .filter((eachContact) => eachContact.IsSigned)
        .map((eachContactPersonUserDetail) =>
          Number(eachContactPersonUserDetail.ContactPersonId)
        )
        .filter((eachValue) => !isNaN(eachValue));
      break;
    case Constants.GeneralInformationQuestion.Q0018:
      contactIds = questionDTO.data.userValue as Array<number>;
      break;
    case Constants.GeneralInformationQuestion.QA0006:
      contactIds = [Number(questionDTO.data.userValue)];
      break;
    default:
      throw new Error("Invalid question code.");
  }
  return contactIds[0] ?? null;
}

export function isEmailMissingForDigitalSigningUsers(
  globalState: CoreInterfaces.AppState
): boolean {
  const result = resolveDigitalSigningContacts(globalState);
  if (!!result) {
    return resolveDigitalSigningContacts(globalState).some(
      (item) => !item.Email
    );
  }
  return true;
}

export function isEmailContainingForbiddenCharactersForDigitalSigningUsers(
  globalState: CoreInterfaces.AppState
): boolean {
  const result = resolveDigitalSigningContacts(globalState);
  if (!!result) {
    return resolveDigitalSigningContacts(globalState).some(
      (item) => !Constants.RegexPatterns.Email.test(item.Email)
    );
  }
  return true;
}
