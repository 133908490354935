import MenuItem from "@mui/material/MenuItem";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useTranslation } from "react-i18next";
import GtDialog from "./GtDialog";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import GtMultilineInput from "./GtMultilineInput";
import { v4 as uuid } from "uuid";
import { formTaskDTO } from "src/utils";

export default function GtTaskFormDialog({
  onSave,
  onDialogClose,
  serviceDTO,
  taskServiceGroup,
  rateCartConfiguration,
}: CorePropsInterfaces.GtTaskFormDialogProps) {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [staffLevel, setStaffLevel] = useState(
    Constants.StaffLevel.ConsultantAccounting
  );
  const [frequency, setFrequency] = useState(Constants.Frequency.Daily);
  const [allocationOfResponsibility, setAllocationOfResponsibility] = useState(
    Constants.TaskAllocationResponsibility.Client
  );
  const [timeDue, setTimeDue] = useState("");
  const [comment, setComment] = useState("");
  const [estimatedTimePerUnit, setEstimatedTimePerUnit] = useState("");
  const [isVisibleOnlyInCalculation, setIsVisibleOnlyInCalculation] =
    useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const isTaskAddedOnlyInCalculations =
    serviceDTO.data.code ===
      Constants.ServiceCode.YearlyInternalDocumentationAndFormalities ||
    serviceDTO.data.code === Constants.ServiceCode.StartUpAccounting;

  let serviceFrequency = [
    Constants.Frequency.Daily,
    Constants.Frequency.OnceAWeek,
    Constants.Frequency.TwiceAWeek,
    Constants.Frequency.EverySecondWeek,
    Constants.Frequency.Monthly,
    Constants.Frequency.Quarterly,
    Constants.Frequency.Yearly,
    Constants.Frequency.WhenNeeded,
  ];
  if (
    serviceDTO.data.recurringType === Constants.ServiceRecurringType.Quarterly
  ) {
    serviceFrequency = [Constants.Frequency.Quarterly];
  }
  if (serviceDTO.data.recurringType === Constants.ServiceRecurringType.Yearly) {
    serviceFrequency = [Constants.Frequency.Yearly];
  }
  if (
    serviceDTO.data.recurringType === Constants.ServiceRecurringType.OneTime
  ) {
    serviceFrequency = [Constants.Frequency.OneTime];
  }
  const canModifyAdjustmentFreq = serviceFrequency.length > 1;

  const taskFormDialogState = {
    data: {
      title: t("General.AddNewTask"),
      maxWidth: "sm",
      rightButtons: [
        <Button
          onClick={() => {
            onDialogClose();
          }}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          onClick={() => handleSaveBtn()}
          variant="contained"
          color="tertiary"
          key="confirmBtn"
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: () => {
        onDialogClose();
      },
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  } as DTOs.GtDialogDTO;

  function handleSaveBtn(): void {
    setIsFormSubmited(true);
    if (title && estimatedTimePerUnit) {
      const taskDTO = formTaskDTO();
      taskDTO.data.code = uuid();
      taskDTO.data.type = Constants.TaskType.ManuallyAdded;
      taskDTO.data.title = title;
      taskDTO.data.timeDue = timeDue;
      taskDTO.data.serviceTaskGroup = taskServiceGroup;
      taskDTO.data.visibility = isVisibleOnlyInCalculation
        ? [Constants.TaskVisibilityArea.Calculation]
        : [
            Constants.TaskVisibilityArea.Calculation,
            Constants.TaskVisibilityArea.Engagement,
          ];
      taskDTO.data.frequency = frequency;
      taskDTO.data.allocationOfResponsibility = allocationOfResponsibility;
      taskDTO.data.canModifyAdjustmentFreq = canModifyAdjustmentFreq;
      taskDTO.data.canModifyAllocationResponsibility = true;
      taskDTO.data.canModifyStaffLevel = true;
      taskDTO.data.comment = comment;
      taskDTO.data.calculationMethod = canModifyAdjustmentFreq
        ? Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour
        : Constants.TaskCalculationMethod.TimeXPricePerHour;
      taskDTO.data.estimatedTimePerUnit = +estimatedTimePerUnit;
      taskDTO.data.staffLevel = staffLevel;
      taskDTO.state.isActive = true;
      onSave(taskDTO);
    }
  }

  useEffect(() => {
    setFrequency(serviceFrequency[0]);
    setIsVisibleOnlyInCalculation(isTaskAddedOnlyInCalculations ? true : false);
  }, [serviceDTO.data.code]);

  return (
    <GtDialog gtDialogDTO={taskFormDialogState}>
      <section>
        <span>{t(serviceDTO?.data.title)}</span>
      </section>
      <article className="gt-form">
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.Task")}
          </span>
          <TextField
            className="gt-TextField"
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {isFormSubmited && !title && (
            <small className="gt-form__fieldSection-error">
              {t("General.FieldMandatory")}
            </small>
          )}
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.Frequency")}
          </span>
          <Select
            className="gt-Select gt-Select--halfWidth"
            value={frequency}
            onChange={(e) =>
              setFrequency(e.target.value as Constants.Frequency)
            }
          >
            {serviceFrequency.map((eachFrequency) => (
              <MenuItem key={`frequency${eachFrequency}`} value={eachFrequency}>
                {t(`Options.${eachFrequency}`)}
              </MenuItem>
            ))}
          </Select>
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.WorkDivision")}
          </span>
          <Select
            className="gt-Select gt-Select--halfWidth"
            value={allocationOfResponsibility}
            onChange={(e) =>
              setAllocationOfResponsibility(
                e.target.value as Constants.TaskAllocationResponsibility
              )
            }
          >
            {[
              Constants.TaskAllocationResponsibility.Client,
              Constants.TaskAllocationResponsibility.GT,
            ].map((eachAllocationResponsibility) => (
              <MenuItem
                key={`frequency${eachAllocationResponsibility}`}
                value={eachAllocationResponsibility}
              >
                {t(`Options.${eachAllocationResponsibility}`)}
              </MenuItem>
            ))}
          </Select>
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.TimeDue")}
          </span>
          <TextField
            className="gt-TextField"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            value={timeDue}
            onChange={(e) => setTimeDue(e.target.value)}
          />
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.EstimatedTimeUnitsInMinutes")}
          </span>
          <TextField
            className="gt-TextField"
            variant="outlined"
            inputProps={{ maxLength: 3 }}
            value={estimatedTimePerUnit}
            onChange={(e: any) => {
              const parsedValue = parseInt(e.target.value);
              if (e.target.value !== parsedValue) {
                e.target.value = isNaN(parsedValue) ? "" : parsedValue;
              }
              setEstimatedTimePerUnit(e.target.value);
            }}
          />
          {isFormSubmited && !estimatedTimePerUnit && (
            <small className="gt-form__fieldSection-error">
              {t("General.FieldMandatory")}
            </small>
          )}
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("General.StaffLevel")}
          </span>
          <Select
            className="gt-Select gt-Select--halfWidth"
            value={staffLevel}
            onChange={(e) =>
              setStaffLevel(e.target.value as Constants.StaffLevel)
            }
          >
            {rateCartConfiguration?.RateCarts.map((eachRateCart) => (
              <MenuItem
                key={`staffLevel${eachRateCart.StaffLevelCode}`}
                value={eachRateCart.StaffLevelCode}
              >
                {t(`Options.${eachRateCart.StaffLevelCode}`)}
              </MenuItem>
            ))}
          </Select>
        </section>
        <section className="gt-form__fieldSection">
          <span className="gt-form__fieldSection-label">
            {t("TableHeader.Comment")}
          </span>
          <GtMultilineInput
            value={comment}
            onChange={(value: string) => setComment(value)}
            maxLength={300}
          />
        </section>
        <section className="gt-form__fieldSection">
          <FormControlLabel
            label={t("General.VisibleOnlyInCalculation")}
            control={
              <Checkbox
                size="small"
                checked={isVisibleOnlyInCalculation}
                onChange={(e) => {
                  !isTaskAddedOnlyInCalculations
                    ? setIsVisibleOnlyInCalculation(e.target.checked)
                    : null;
                }}
              />
            }
          />
        </section>
      </article>
    </GtDialog>
  );
}
