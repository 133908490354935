import { findQuestionInService } from "src/utils/shared";
import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "./../../../core/Constants";
import { t } from "i18next";
import { resolveService7Pricelist } from "src/utils/service-utils";
import { mapQuestionCodesToQuestionDTOs } from "src/utils/question-utils";

export const PayrollAndExpenseAndTravelInvoiceManagementProcessor: CoreInterfaces.QuestionProcessors =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO) => {
    const {
      Q0701,
      Q0702,
      Q0703,
      Q0704,
      Q0707,
      Q0708,
      Q0709,
      Q0710,
      Q0711,
      Q0712,
      Q0713,
      Q0714,
      Q0722,
      Q0725,
      Q0728,
      Q0732,
      Q0750,
      Q0751,
      Q0753,
      Q0755,
      Q0756,
      Q0759,
      Q0760,
      Q0765,
      Q0766,
    } = Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions;
    const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
      Q0701,
      Q0702,
      Q0703,
      Q0704,
      Q0707,
      Q0708,
      Q0709,
      Q0710,
      Q0711,
      Q0712,
      Q0713,
      Q0714,
      Q0722,
      Q0725,
      Q0728,
      Q0732,
      Q0750,
      Q0751,
      Q0753,
      Q0755,
      Q0756,
      Q0759,
      Q0760,
      Q0765,
      Q0766,
    ]);

    let initialValues: any = {};
    if (appState.services.length > 0) {
      const service = appState.services.find(
        (service) =>
          service.data.code ===
          Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
      );
      const { Q0755, Q0756 } =
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions;
      initialValues = mapQuestionCodesToQuestionDTOs(service, [Q0755, Q0756]);
    }

    return {
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0702]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0701?.state.isShown &&
          questionDTOMap.Q0701?.data.userValue ===
            Constants.HelpfulConstants.OneEmployee;

        resolveService7Pricelist(serviceDTO);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0703]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          (questionDTOMap.Q0701.state.isShown &&
            parseInt(questionDTOMap.Q0701.data.userValue as string) <=
              parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees)) ||
          (questionDTOMap.Q0702.state.isShown &&
            questionDTOMap.Q0702.data.userValue === Constants.YesNo.No);
        resolveService7Pricelist(serviceDTO);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0704]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0703?.state.isShown &&
          questionDTOMap.Q0703?.data.userValue === Constants.YesNo.No;

        resolveService7Pricelist(serviceDTO);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0705]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          (questionDTOMap.Q0701?.state.isShown &&
            parseInt(questionDTOMap.Q0701?.data.userValue as string) >
              parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees)) ||
          (questionDTOMap.Q0703.state.isShown &&
            questionDTOMap.Q0703.data.userValue === Constants.YesNo.Yes) ||
          (questionDTOMap.Q0704.state.isShown &&
            questionDTOMap.Q0704.data.userValue !==
              Constants.CollectiveAgreement.NoAgreement &&
            questionDTOMap.Q0704.data.userValue !==
              Constants.HelpfulConstants.PleaseSelect);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0706]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0704.state.isShown &&
          questionDTOMap.Q0704.data.userValue ===
            Constants.CollectiveAgreement.Other;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0707]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          (questionDTOMap.Q0701.state.isShown &&
            parseInt(questionDTOMap.Q0701.data.userValue as string) >
              parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees)) ||
          (questionDTOMap.Q0703.state.isShown &&
            questionDTOMap.Q0703.data.userValue === Constants.YesNo.Yes) ||
          (questionDTOMap.Q0704.state.isShown &&
            questionDTOMap.Q0704.data.userValue !==
              Constants.CollectiveAgreement.NoAgreement &&
            questionDTOMap.Q0704.data.userValue !==
              Constants.HelpfulConstants.PleaseSelect);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0708]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0707.state.isShown &&
          questionDTOMap.Q0707.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0709]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0702.state.isShown &&
          questionDTOMap.Q0702.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0710]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0701.state.isShown &&
          parseInt(questionDTOMap.Q0701.data.userValue as string) <=
            parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees) &&
          questionDTOMap.Q0704.state.isShown &&
          questionDTOMap.Q0704.data.userValue ===
            Constants.CollectiveAgreement.NoAgreement &&
          questionDTOMap.Q0703.state.isShown &&
          questionDTOMap.Q0703.data.userValue === Constants.YesNo.No &&
          (!questionDTOMap.Q0702.state.isShown ||
            (questionDTOMap.Q0702.state.isShown &&
              questionDTOMap.Q0702.data.userValue !== Constants.YesNo.Yes));
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0711]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0701.state.isShown &&
          parseInt(questionDTOMap.Q0701.data.userValue as string) <=
            parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees) &&
          questionDTOMap.Q0704.state.isShown &&
          questionDTOMap.Q0704.data.userValue !==
            Constants.CollectiveAgreement.NoAgreement &&
          questionDTOMap.Q0704.data.userValue !==
            Constants.HelpfulConstants.PleaseSelect &&
          questionDTOMap.Q0703.state.isShown &&
          questionDTOMap.Q0703.data.userValue === Constants.YesNo.No &&
          (!questionDTOMap.Q0702.state.isShown ||
            (questionDTOMap.Q0702.state.isShown &&
              questionDTOMap.Q0702.data.userValue !== Constants.YesNo.Yes));
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0712]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          ((questionDTOMap.Q0701?.state.isShown &&
            parseInt(questionDTOMap.Q0701?.data.userValue as string) >
              parseInt(Constants.HelpfulConstants.PayrollNumberOfEmployees)) ||
            (questionDTOMap.Q0703.state.isShown &&
              questionDTOMap.Q0703.data.userValue === Constants.YesNo.Yes)) &&
          (!questionDTOMap.Q0702.state.isShown ||
            (questionDTOMap.Q0702.state.isShown &&
              questionDTOMap.Q0702.data.userValue !== Constants.YesNo.Yes));
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0713]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0707.state.isShown &&
          questionDTOMap.Q0707.data.userValue === Constants.YesNo.No;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0714]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0707.state.isShown &&
          questionDTOMap.Q0707.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0715]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0714 = findQuestionInService(
          serviceDTO,
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0714
        );
        questionDTO.state.isShown =
          q0714.state.isShown && q0714.data.userValue === Constants.YesNo.No;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0717]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const scenario =
          questionDTOMap.Q0765.data.userValue === Constants.YesNo.No
            ? questionDTOMap.Q0766.data.userValue
            : questionDTOMap.Q0709.state.isShown
            ? t(questionDTOMap.Q0709.data.text)
            : questionDTOMap.Q0710.state.isShown
            ? t(questionDTOMap.Q0710.data.text)
            : questionDTOMap.Q0711.state.isShown
            ? t(questionDTOMap.Q0711.data.text)
            : questionDTOMap.Q0712.state.isShown
            ? t(questionDTOMap.Q0712.data.text)
            : "";
        questionDTO.data.translationParams = { scenario };
        questionDTO.state.isShown =
          questionDTOMap.Q0709.state.isShown ||
          questionDTOMap.Q0710.state.isShown ||
          questionDTOMap.Q0711.state.isShown ||
          questionDTOMap.Q0712.state.isShown;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0718]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const payrollPreSystem = questionDTOMap.Q0708.state.isShown
          ? questionDTOMap.Q0708.data.userValue
          : questionDTOMap.Q0713.state.isShown
          ? t(questionDTOMap.Q0713.data.text)
          : "";
        questionDTO.data.translationParams = { payrollPreSystem };
        questionDTO.state.isShown =
          questionDTOMap.Q0708.state.isShown ||
          questionDTOMap.Q0713.state.isShown;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0723]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0722.state.isShown &&
          questionDTOMap.Q0722.data.userValue === Constants.YesNo.No;
      },

      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0726]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const services: Array<Constants.ServiceCode> = [
          Constants.ServiceCode.AccountsReceivable,
          Constants.ServiceCode.AccountsPayable,
          Constants.ServiceCode.CorporateCardManagement,
          Constants.ServiceCode.OtherAccountAndReconciliation,
          Constants.ServiceCode.PeriodReporting,
          Constants.ServiceCode.InterimListedCompanies,
          Constants.ServiceCode.AnnualReporting,
          Constants.ServiceCode.AnnualReportingListedCompanies,
        ];
        const hasMatchingServices = appState.services.some(
          (eachService) =>
            eachService.state.isSelected &&
            services.includes(eachService.data.code)
        );

        questionDTO.state.isShown =
          !hasMatchingServices &&
          questionDTOMap.Q0702.state.isShown &&
          questionDTOMap.Q0702.data.userValue === Constants.YesNo.No;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0727]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0725.state.isShown &&
          questionDTOMap.Q0725.data.userValue === Constants.YesNo.No;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0748]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0732.state.isShown &&
          questionDTOMap.Q0732.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0751]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0750.state.isShown &&
          questionDTOMap.Q0750.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0752]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0751.state.isShown &&
          questionDTOMap.Q0751.data.userValue === Constants.YesNo.No;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0753]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0750.state.isShown &&
          questionDTOMap.Q0750.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0754]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0750.state.isShown &&
          questionDTOMap.Q0750.data.userValue === Constants.YesNo.Yes &&
          questionDTOMap.Q0753.state.isShown &&
          questionDTOMap.Q0753.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0755]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0750.state.isShown &&
          questionDTOMap.Q0750.data.userValue === Constants.YesNo.Yes &&
          questionDTOMap.Q0753.state.isShown &&
          questionDTOMap.Q0753.data.userValue === Constants.YesNo.No;
        if (
          !!initialValues.Q0755 &&
          (initialValues.Q0755.data.userValue === Constants.YesNo.No ||
            initialValues.Q0755.data.userValue === "") &&
          initialValues.Q0755.data.userValue !== questionDTO.data.userValue &&
          questionDTO.data.userValue !== Constants.YesNo.No
        ) {
          questionDTOMap.Q0756.data.userValue = Constants.YesNo.No;
        }
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0756]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const initialState =
          !!initialValues.Q0756 && initialValues.Q0756.state.isShown;
        questionDTO.state.isShown =
          questionDTOMap.Q0750.state.isShown &&
          questionDTOMap.Q0750.data.userValue === Constants.YesNo.Yes &&
          questionDTOMap.Q0753.state.isShown &&
          questionDTOMap.Q0753.data.userValue === Constants.YesNo.No &&
          (serviceDTO.data.priceList === Constants.PriceList.PriceList3 ||
            serviceDTO.data.priceList === Constants.PriceList.PriceList4);

        if (
          !!initialValues.Q0755 &&
          initialState !== questionDTO.state.isShown &&
          initialValues.Q0755.data.userValue === questionDTO.data.userValue &&
          initialValues.Q0755.data.userValue === Constants.YesNo.Yes
        ) {
          questionDTO.data.userValue = Constants.YesNo.No;
        }
        if (
          !!initialValues.Q0756 &&
          initialValues.Q0756.data.userValue === Constants.YesNo.No &&
          initialValues.Q0756.data.userValue !== questionDTO.data.userValue
        ) {
          questionDTOMap.Q0755.data.userValue = Constants.YesNo.No;
        }
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0758]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const service = appState.services.find(
          (s) => s.data.code === Constants.ServiceCode.AnnualPayrollRoutines
        );
        if (!!service) {
          const t0743 = service?.data.tasks.find(
            (t) => t.data.code === Constants.AnnualPayrollRoutinesTasks.T0743
          );
          t0743.state.isVisible =
            questionDTO.data.userValue === Constants.YesNo.Yes;
        }
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0760]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0728.state.isShown &&
          questionDTOMap.Q0728.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0761]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0760.state.isShown &&
          questionDTOMap.Q0760.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0762]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0759.state.isShown &&
          (questionDTOMap.Q0759.data.userValue ===
            Constants.VacationYear.PreviousVacation ||
            questionDTOMap.Q0759.data.userValue ===
              Constants.VacationYear.Other);
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0763]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.data.options = [
          {
            key: Constants.HelpfulConstants.PleaseSelect,
            value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
          },
          ...(appState.remoteData?.customerContactPersons || []),
        ];
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0766]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          questionDTOMap.Q0765.state.isShown &&
          questionDTOMap.Q0765.data.userValue == Constants.YesNo.No;
      },
    };
  };
