import { CoreInterfaces, DTOs } from "src/core/Models";
import { t } from "i18next";
import i18n from "src/i18n";
import * as Constants from "src/core/Constants/Constants";
import { License } from "src/core/Models/Core.interface";
import { isAdministrator, isOfficeManager } from "./user-roles-utils";
import { getEmail } from "./auth";
import { applyUpdatesOnQuestionChange } from "./processors/Questions";
import { formServiceDTOs } from "./dto";
import { getServicesForBusinessUnit } from "./service-utils";
import { servicesConfiguration } from "src/core/Configurations/Services.config";
import * as StateProcessors from "../core/Context/state-processors";

export function formatMinutesToHoursAndMinutes(allMinutesNo: number): string {
  const hoursNo = Math.floor(allMinutesNo / 60);
  const minutesNo = Math.ceil(allMinutesNo - 60 * hoursNo);
  const hours = hoursNo < 10 ? `0${hoursNo}` : hoursNo;
  const minutes = minutesNo < 10 ? `0${minutesNo}` : minutesNo;
  return t("General.HoursAndMinutes", {
    hours,
    minutes,
  });
}

export function convertStringIntoDate(item: string): string {
  const currentLanguage = getLanguage();

  return new Date(item).toLocaleString(currentLanguage, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getLanguage(): string {
  switch (i18n.language) {
    case "en":
      return "en";
    case "se":
      return "se";
    default:
      return "en";
  }
}

export function createGeneralVariables(): CoreInterfaces.GeneralVariables {
  return {
    organisationNo: "",
    companyName: "",
    address: "",
    postalNumber: "",
    area: "",
    contactPerson: "",
    contactPersonAccounting: "",
    contactPersonPayroll: "",
    accountYearEndMonth: "",
    accountingMethod: "",
    customerBank: "",
    costCenter: "",
    isCompanyRegisteredVAT: false,
    declarationFrequencyVAT: "",
    isAuditorGrantThornton: false,
    accountingSystem: "",
    isDefaultAccountingSystem: true,
    accountsReceivableNumber: "",
    accountsReceivablePreSystem: "",
    accountsReceivableFrequency: "",
    accountsReceivableInvoiceTiming: "",
    accountsReceivableCreator: "",
    accruesPreparedRevenue: "",
    contactPersonAccountReceivable: "",
  };
}

export function createInitialState(): CoreInterfaces.AppState {
  return {
    generalVariables: createGeneralVariables(),
    services: [],
    currentConfiguration: {
      id: null,
      engagementLetterParentId: null,
      version: 0,
      businessUnit: null,
      lastModifiedDate: null,
      organisationNumber: null,
      companyName: null,
      status: null,
      description: null,
      applicationConfiguration: null,
      createdBy: null,
      createdDate: null,
      createdByUID: null,
      signingDate: null,
      signingStatus: null,
      lastAccessedTime: null,
      consent: null,
      digitalSigningDetails: null,
      totalCost: null,
      totalCostManualOverride: null,
      totalWorkingHours: null,
      crmRowId: null,
      validFromDate: null,
      engagementLetterMetadata: null,
      owner: null,
      validUntilDate: null,
      isHiddenFromImporting: false,
      engagementDocumentsLanguage: null,
      lastUserChangesDate: null,
      team: null,
    },
    remoteData: {
      customerContactPersons: [],
      gtContactPersons: [],
      companyDetails: null,
      businessOpportunityInfo: null,
      systemAdministrationMessages: [],
      employeesOffices: [],
      userRoles: [],
      officeManagers: [],
      teams: [],
    },
    state: {
      isPageBlurred: false,
      isRatesChangeMessagesShow: false,
    },
    taskAllocations: [],
    taskCosts: [],
    offices: [],
    authProviderUserDetails: null,
    language: Constants.Languages.SE,
    areUnsavedChanges: false,
    unsavedChangesTimestamp: null,
    applicationConfCurrentVersions: {
      rateCart: null,
      taskCost: null,
      taskAllocation: null,
    },
    showConflictUpdateDialog: false,
    isConflictOnUpdateEngagement: false,
    lastModifiedBy: null,
  };
}

export function getInitialState(businessUnit: string): CoreInterfaces.AppState {
  const initialAppState = createInitialState();

  const serviceHandlingFunction = (service: DTOs.ServiceDTO) => {
    return applyUpdatesOnQuestionChange(initialAppState, service);
  };

  const services = formServiceDTOs(
    getServicesForBusinessUnit(
      servicesConfiguration,
      businessUnit as Constants.BusinessUnit
    ),
    serviceHandlingFunction
  );

  const generalInformation = services.find(
    (service) => service.data.code === Constants.ServiceCode.GeneralInformation
  );
  const generalVariables =
    StateProcessors.createGeneralVariablesObject(generalInformation);
  generalInformation.data.questions.forEach((eachGeneralQuestion) => {
    StateProcessors.updateServicesOnGeneralQuestionChange(
      services,
      eachGeneralQuestion,
      generalVariables
    );
  });

  initialAppState.services = services;
  initialAppState.generalVariables = generalVariables;

  return initialAppState;
}

// @TODO - refactor this by using the formAdditionalCostDTO function as base
export const createAdditionalCostDTOFromLicense = (
  license: License
): DTOs.AdditionalCostDTO => {
  const object: DTOs.AdditionalCostDTO = {
    data: {
      code: license.Uuid as Constants.AdditionalCostConfigurationCode,
      cost: Number(license.PricePerMonth),
      valueQuestionCode: null,
      numberOfUnits: Number(license.NumberOfUnits),
      text: license.LicenseName,
      tooltip: "",
      description: "",
      adjustments: {
        Cost: null,
        ExtendedPriceListPanelCost: null,
      },
      serviceTaskGroup: Constants.TaskGroup.DefaultGroup,
      isFrontendObject: true,
    },
    state: {
      isVisible: true,
      isUsedInSoftwareServiceCostsTable: true,
      isUsedInSoftwareServiceFixedPriceTable: true,
    },
  };

  return object;
};

export function ensureEventValueIsAValidInteger(evt: any) {
  const parsedValue = parseInt(evt.target.value);
  if (evt.target.value !== parsedValue) {
    evt.target.value = isNaN(parsedValue) ? "" : parsedValue;
  }
}

export function getRelevantPageForNavigation(
  configurationStatus: Constants.ConfigurationStatus,
  nextRoutesMap: { [key in Constants.ConfigurationStatus]: Constants.Routes },
  businessOpportunityId: string,
  clientId: string,
  unit: string,
  engagementConfigurationId: string
): string {
  let nextURL = nextRoutesMap[configurationStatus] ?? Constants.Routes.Home;
  const overrideRedirectRoute = localStorage.getItem(
    Constants.LOCAL_STORAGE_FORCE_REDIRECT_KEY
  );

  if (overrideRedirectRoute) {
    nextURL = overrideRedirectRoute as Constants.Routes;
  }

  if (
    location.pathname !== `/${nextURL}` &&
    location.pathname !== `/${Constants.Routes.EngagementLettersSummaryView}`
  ) {
    const queryParams = new URLSearchParams();
    queryParams.set(
      Constants.QueryParams.businessOpportunityId,
      businessOpportunityId
    );
    queryParams.set(Constants.QueryParams.clientId, clientId);
    queryParams.set(Constants.QueryParams.unit, unit);

    if (engagementConfigurationId) {
      queryParams.set(
        Constants.QueryParams.engagementConfigurationId,
        engagementConfigurationId
      );
    }
    return `/${nextURL}?${queryParams}`;
  }

  return null;
}

export function wait(ms: number) {
  const start = +new Date();
  while (+new Date() - start < ms);
}

export function getRelevantGtOwners(globalState: CoreInterfaces.AppState): any {
  if (isAdministrator(globalState)) {
    return globalState.remoteData.gtContactPersons;
  } else if (isOfficeManager(globalState)) {
    const claims = globalState.authProviderUserDetails?.claims;
    const email = claims ? getEmail(claims) : "";
    const currentUserId = globalState.remoteData.gtContactPersons.find(
      (item) => item.email === email
    )?.key;
    if (currentUserId) {
      const currentUserOffices = globalState.remoteData.officeManagers
        .filter((officeManager) => officeManager.UserId === currentUserId)
        .map((officeManager) => officeManager.Office);

      return globalState.remoteData.gtContactPersons.filter((item) =>
        currentUserOffices.includes(item.office)
      );
    }
  }
  return [];
}
